/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Button, PasswordInput, Snackbar } from '@equipe-ninja/saraiva-ui'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import { useBrands } from '../themes/hook'
import Accounts from '../services/accounts'
import Links from '../config/Links'
import Messages from '../config/Messages'
import { isEqual, isStrength, isEmpty } from './Validators'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    marginTop: '48px',
    marginBottom: '64px',
    marginLeft: '24px',
    marginRight: '24px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
      marginLeft: '15%',
      marginRight: '15%',
      marginBottom: '0px',
      alignSelf: 'center'
    }
  },
  backButton: {
    marginTop: '16px',
    marginRight: '0px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '0px',
      marginRight: '12px'
    }
  }
}))

export default function ResetPassword() {
  const classes = useStyles()
  const { brand } = useBrands()
  const brandClasses = brand.classes()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState({
    password: { error: false, message: Messages.passwordCharacters }
  })
  const [verifyConfirmPassword, setVerifyConfirmPassword] = useState({
    confirmPassword: { error: false, message: '' }
  })
  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false
  })
  const [disabledButton, setDisabledButton] = useState(true)
  const { token } = useParams()
  const history = useHistory()

  function inputError() {
    if (
      verifyPassword.password.error ||
      verifyConfirmPassword.confirmPassword.error ||
      password.length === 0 ||
      confirmPassword.length === 0
    )
      setDisabledButton(true)
    else setDisabledButton(false)
  }

  useEffect(() => {
    inputError()
  })

  async function resetPassword() {
    const data = {
      password,
      token
    }

    try {
      const response = await Accounts.updatePassword(data)
      if (response.status === 204) {
        setSnackbar({
          children: Messages.passwordReset,
          severity: 'success',
          show: true
        })
      } else if (response.status === 401) {
        setSnackbar({
          children: Messages.expiredToken,
          severity: 'error',
          show: true
        })
      } else {
        setSnackbar({
          children: Messages.systemUnavailable,
          severity: 'error',
          show: true
        })
      }
    } catch (err) {
      setSnackbar({
        children: Messages.systemUnavailable,
        severity: 'error',
        show: true
      })
    }
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyPassword({
        password: {
          error: true,
          message: Messages.requiredField
        }
      })
    } else if (!isStrength(event.target.value)) {
      setVerifyPassword({
        password: { error: true, message: Messages.passwordCharacters }
      })
    } else {
      setVerifyPassword({ password: { error: false, message: '' } })
    }
  }

  function handleConfirmPasswordChange(event) {
    setConfirmPassword(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyConfirmPassword({
        confirmPassword: {
          error: true,
          message: Messages.requiredField
        }
      })
    } else if (!isEqual(password, event.target.value)) {
      setVerifyConfirmPassword({
        confirmPassword: {
          error: true,
          message: Messages.equalPassword
        }
      })
    } else {
      setVerifyConfirmPassword({
        confirmPassword: { error: false, message: '' }
      })
    }
  }

  const handleClose = () => {
    setSnackbar({ children: '', severity: 'info', show: false })
  }

  return (
    <>
      <Snackbar
        children={snackbar.children}
        severity={snackbar.severity}
        show={snackbar.show}
        handleClose={handleClose}
      />

      <form className={classes.mainContainer}>
        <Box display={{ xs: 'block', md: 'none' }} mb={8}>
          <a href={Links.login}>
            <img
              src={brand.assets.logoDark}
              alt={brand.assets.logoLabel}
              className={brandClasses.mobileLogo}
            />
          </a>
        </Box>
        <Box mb={6}>
          <Typography variant="h4">Redefinir senha</Typography>
        </Box>
        <Box className={brandClasses.textInputBox}>
          <PasswordInput
            onChange={handlePasswordChange}
            helperText={verifyPassword.password.message}
            error={verifyPassword.password.error}
            fullWidth
            id="password"
            label="Crie nova senha"
            value={password}
            color="primary"
          />
        </Box>
        <Box mt={3} className={brandClasses.textInputBox}>
          <PasswordInput
            onChange={handleConfirmPasswordChange}
            helperText={verifyConfirmPassword.confirmPassword.message}
            error={verifyConfirmPassword.confirmPassword.error}
            fullWidth
            id="confirmPassword"
            label="Confirme nova senha"
            value={confirmPassword}
            color="primary"
          />
        </Box>
        <Box dir="rtl" mt={6}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Button
                disabled={disabledButton}
                color="primary"
                fullWidth
                variant="contained"
                size="small"
                onClick={() => resetPassword()}
                className={brandClasses.button}
              >
                Redefinir Senha
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} className={classes.backButton}>
              <Button
                color="primary"
                fullWidth
                size="small"
                onClick={() => history.push(Links.login)}
                className={brandClasses.textLink}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}
