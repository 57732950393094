import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import CreateAccount from './components/CreateAccount'
import CreateAccountWithCpf from './components/CreateAccountWithCpf'
import Error from './components/Error'
import PasswordRecovery from './components/PasswordRecovery'
import Layout from './layouts/Layout'
import Login from './components/Login'
import Logout from './components/Logout'
import AutoLogin from './components/AutoLogin'
import ResetPassword from './components/ResetPassword'

import Links from './config/Links'
import Unauthorized from './components/Unauthorized'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Links.login}>
          {Layout(Login)}
        </Route>
        <Route exact path={Links.logout}>
          {Layout(Logout)}
        </Route>
        <Route exact path={Links.refresh_session}>
          {Layout(AutoLogin)}
        </Route>
        <Route exact path={Links.create_account}>
          {Layout(CreateAccount)}
        </Route>
        <Route exact path={Links.create_account_with_cpf}>
          {Layout(CreateAccountWithCpf)}
        </Route>
        <Route exact path={Links.create_affiliate_account}>
          {Layout(() => (
            <CreateAccountWithCpf isCreatingAffiliateAccount />
          ))}
        </Route>
        <Route exact path={Links.password_recovery}>
          {Layout(PasswordRecovery)}
        </Route>
        <Route exact path={Links.reset_password}>
          {Layout(ResetPassword)}
        </Route>
        <Route exact path={Links.unauthorized}>
          {Layout(Unauthorized)}
        </Route>
        <Route path="*">{Error()}</Route>
      </Switch>
    </BrowserRouter>
  )
}
