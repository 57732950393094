/* eslint-disable */
import httpClient from './http_request'

const Trackings = {
  async sendTrack(data) {
    const path = 'anonymous_trackings'
    let headers = {
      'session-token': localStorage.getItem('globalToken')
    }

    headers = httpClient.buildHeader(headers)

    data = {
      ...data,
      payload: {
        ...data.payload,
        context_screen: window.screen.width,
        context_locale: navigator.language,
        context_page_path: window.location.pathname,
        context_page_referrer: document.referrer,
        context_page_url: window.location.href,
        context_user_agent: navigator.userAgent
      }
    }

    const response = await httpClient.post(path, data, headers)
    return response
  },

  async login(data, requestResponse, responseData) {
    try {
      const response = await Trackings.sendTrack({
        event: 'clicked_enter',
        payload: {
          email: data.email,
          error_message: data.errorMessage,
          status_response: requestResponse?.status,
          error_detail: responseData,
          session_token: localStorage.getItem('globalToken'),
          app_token: localStorage.getItem('externalApplicationToken')
        }
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      // TODO: SEND TO BUGSNAG
      console.error(error)
    }
  }
}

export default Trackings
