import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Link, Typography } from '@material-ui/core'
import {
  Button,
  PasswordInput,
  Snackbar,
  TextInput
} from '@equipe-ninja/saraiva-ui'

import Accounts from '../services/accounts'
import Links from '../config/Links'
import Messages from '../config/Messages'
import { isEmail, isEmpty } from './Validators'
import Trackings from '../services/trackings'
import { saveGlobalToken, redirectToApplication } from '../utils'
import { useBrands } from '../themes/hook'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    marginTop: '48px',
    marginBottom: '64px',
    marginLeft: '24px',
    marginRight: '24px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
      marginLeft: '15%',
      marginRight: '15%',
      marginBottom: '0px',
      alignSelf: 'center'
    }
  }
}))

export default function Login() {
  const classes = useStyles()
  const { brand } = useBrands()
  const brandClasses = brand.classes()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verifyEmail, setVerifyEmail] = useState({
    email: { error: false, message: '' }
  })
  const [verifyPassword, setVerifyPassword] = useState({
    password: { error: false, message: '' }
  })
  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false
  })
  const [disabledButton, setDisabledButton] = useState(true)

  const externalApplicationToken = localStorage.getItem(
    'externalApplicationToken'
  )

  const history = useHistory()

  function inputError() {
    if (
      verifyEmail.email.error ||
      verifyPassword.password.error ||
      email.length === 0 ||
      password.length === 0
    )
      setDisabledButton(true)
    else setDisabledButton(false)
  }

  useEffect(() => {
    inputError()
  })

  async function login() {
    let data = {
      email,
      password,
      integration_type: 'email_password',
      external_application_token: externalApplicationToken
    }

    try {
      const response = await Accounts.login(data)
      const responseData = await response.json()

      if (response.status === 201) {
        const {
          previous_url: previousUrl,
          tokens: { local_token: localToken, global_token: globalToken }
        } = responseData

        saveGlobalToken(globalToken)

        window.setTimeout(() => {
          redirectToApplication(localToken, previousUrl)
        }, 1000)
      } else {
        Trackings.login(data, response, responseData)

        if (
          response.status === 401 &&
          responseData.error === 'user do not have access to application'
        ) {
          history.push(Links.unauthorized)
        } else if (
          response.status === 401 &&
          responseData.errors.detail === 'Unauthorized'
        ) {
          setSnackbar({
            children: Messages.incorrectData,
            severity: 'error',
            show: true
          })
        } else {
          setSnackbar({
            children: Messages.systemUnavailable,
            severity: 'error',
            show: true
          })
        }
      }
    } catch (err) {
      data = { ...data, errorMessage: err.message }
      Trackings.login(data)
      setSnackbar({
        children: Messages.systemUnavailable,
        severity: 'error',
        show: true
      })
    }
  }

  function handleEmailChange(event) {
    setEmail(event.target.value)

    if (isEmpty(event.target.value)) {
      setVerifyEmail({
        email: { error: true, message: Messages.requiredField }
      })
    } else if (!isEmail(event.target.value)) {
      setVerifyEmail({
        email: {
          error: true,
          message: `${event.target.value}${Messages.invalidEmail}`
        }
      })
    } else {
      setVerifyEmail({ email: { error: false, message: '' } })
    }
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyPassword({
        password: {
          error: true,
          message: Messages.requiredField
        }
      })
    } else {
      setVerifyPassword({ password: { error: false, message: '' } })
    }
  }

  const handleClose = () => {
    setSnackbar({ children: '', severity: 'info', show: false })
  }

  useEffect(() => {
    if (brand?.name === 'lfgModern') {
      window.setGtag()
    }
  }, [brand.name])

  return (
    <>
      <Snackbar
        // eslint-disable-next-line
        children={snackbar.children}
        severity={snackbar.severity}
        show={snackbar.show}
        handleClose={handleClose}
      />

      <form className={classes.mainContainer} onSubmit={() => login()}>
        <Box display={{ xs: 'block', md: 'none' }} mb={8}>
          <a href={Links.login}>
            <img
              src={brand.assets.logoDark}
              alt={brand.assets.logoLabel}
              className={brandClasses.mobileLogo}
            />
          </a>
        </Box>
        <Box mb={6}>
          <Typography variant="h4">Acessar minha conta</Typography>
        </Box>
        <Box className={brandClasses.textInputBox}>
          <TextInput
            onChange={handleEmailChange}
            helperText={verifyEmail.email.message}
            error={verifyEmail.email.error}
            fullWidth
            id="email"
            type="email"
            label="E-mail"
            value={email}
            color="primary"
          />
        </Box>
        <Box mt={3} className={brandClasses.textInputBox}>
          <PasswordInput
            onChange={handlePasswordChange}
            helperText={verifyPassword.password.message}
            error={verifyPassword.password.error}
            fullWidth
            id="password"
            label="Senha"
            value={password}
            color="primary"
          />
        </Box>
        <Box mt={3} dir="rtl">
          <Typography>
            <Link
              href={Links.password_recovery}
              className={brandClasses.textLink}
            >
              Esqueci minha senha
            </Link>
          </Typography>
        </Box>
        <Box mt={6}>
          <Button
            disabled={disabledButton}
            color="primary"
            fullWidth
            variant="contained"
            size="small"
            type="submit"
            onClick={() => login()}
            className={brandClasses.button}
          >
            Acessar
          </Button>
        </Box>
        <Box mt={3}>
          <Typography align="center" color="textSecondary">
            Novo por aqui?&nbsp;
            <Link
              href={
                brand.name === 'lfgModern'
                  ? Links.create_account_with_cpf
                  : Links.create_account
              }
              className={brandClasses.textLink}
            >
              Criar conta
            </Link>
          </Typography>
        </Box>
      </form>
    </>
  )
}
