import { makeStyles } from '@material-ui/core'

import Background from '../../static/images/background_lfg_classic.png'
import Logo from '../../static/images/logo_fundo_escuro_lfg_classic.png'
import LogoDark from '../../static/images/logo_fundo_claro_lfg_classic.png'
import Messages from '../../config/Messages'

/**
 * Os elementos abaixo são obrigatórios
 * os estilos informados são o padrão
 * demais estilos são opcionais
 *
 * @containerBranding
 * coluna da esquerda que contém a
 * logo e um pequeno texto
 *
 * @button
 * botões de submit dos forms
 *
 * @textLink
 * links de resetar senha, criar conta etc
 *
 * @logo
 * logo localizada na coluna da esquerda
 */
export const useLFGClassicClasses = makeStyles({
  containerBranding: {
    backgroundImage: `url(${Background})`
  },
  button: {
    backgroundColor: '#165AAA',

    '&:hover': {
      backgroundColor: '#000082'
    }
  },
  textLink: {
    color: '#165AAA'
  },
  textInputBox: {
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#165AAA'
    }
  },
  logo: {
    height: '142px',
    display: 'block',
    margin: '0 auto 10px'
  },
  mobileLogo: {
    height: '42px'
  }
})

export const LFGClassicAssets = {
  logo: Logo,
  logoDark: LogoDark,
  logoLabel: 'LFG Classic Logo',
  background: Background,
  subtitle: Messages.initialMessage
}
