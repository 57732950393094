import { noBrandingAssets, useNoBrandingClasses } from './noBranding'
import { saraivaAssets, useSaraivaClasses } from './saraiva'
import { LFGClassicAssets, useLFGClassicClasses } from './lfgClassic'
import { LFGModernAssets, useLFGModernClasses } from './lfgModern'

const BrandAssetsAndClasses = {
  noBranding: {
    name: '',
    assets: noBrandingAssets,
    classes: useNoBrandingClasses
  },
  saraiva: {
    name: 'saraiva',
    assets: saraivaAssets,
    classes: useSaraivaClasses
  },
  lfgClassic: {
    name: 'lfgClassic',
    assets: LFGClassicAssets,
    classes: useLFGClassicClasses
  },
  lfgModern: {
    name: 'lfgModern',
    assets: LFGModernAssets,
    classes: useLFGModernClasses
  }
}

/**
 *
 * @param {string} nameToValidate
 * @returns boolean
 *
 * confere se existe alguma marca
 * com o nome fornecido
 */
export const isBrandNameValid = (nameToValidate) => {
  const keys = Object.keys(BrandAssetsAndClasses)
  return keys.some((key) => {
    return key === nameToValidate
  })
}

export default BrandAssetsAndClasses
