import React from 'react'
import ReactDOM from 'react-dom'
import { SaraivaTheme } from '@equipe-ninja/saraiva-ui'
import * as serviceWorker from './serviceWorker'
import { BrandsProvider } from './themes/hook'
import Routes from './routes'

ReactDOM.render(
  <SaraivaTheme>
    <BrandsProvider>
      <Routes />
    </BrandsProvider>
  </SaraivaTheme>,
  document.getElementById('root')
)

serviceWorker.unregister()
