export const affiliateOriginUrl = (origin) => {
  if (origin.match(/localhost/)) {
    return 'http://localhost:5000'
  }

  if (origin.match(/stg\.sso\.saraiva/)) {
    return 'https://stg.loja.lfg.saraivaeducacao.com.br'
  }

  return 'https://www.lfg.com.br/'
}
