import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

import Links from '../config/Links'
import { useBrands } from '../themes/hook'

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: 24
  },
  mainContainer: {
    width: '100%',
    marginLeft: '24px',
    marginRight: '24px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
      marginLeft: '15%',
      marginRight: '15%',
      marginBottom: '0px',
      alignSelf: 'center'
    }
  },
  title: {
    marginBottom: 32
  },
  email: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export default function Unauthorized() {
  const classes = useStyles()

  const { brand } = useBrands()

  const brandClasses = brand.classses()

  return (
    <>
      <Box className={classes.logo} display={{ xs: 'block', md: 'none' }}>
        <a href={Links.login}>
          <img
            src={brand.assets.logo}
            alt={brand.assets.logoLabel}
            className={brandClasses.mobileLogo}
          />
        </a>
      </Box>
      <Box className={classes.mainContainer}>
        <Typography className={classes.title} variant="h4">
          Você não tem permissão de acesso ao BackOffice
        </Typography>
        <Typography color="textSecondary" variant="body1">
          Se você é funcionário da Saraiva Educação e necessita do acesso ao
          BackOffice, entre em contato pelo email abaixo:
        </Typography>
        <a
          target="_top"
          href="mailto:timedeprodutosets@kroton.onmicrosoft.com"
          className={classes.email}
        >
          <Typography variant="body1" style={{ marginTop: 16 }}>
            timedeprodutosets@kroton.onmicrosoft.com
          </Typography>
        </a>
      </Box>
    </>
  )
}
