import { makeStyles } from '@material-ui/core'

import Background from '../../static/images/background.png'
import Logo from '../../static/images/logo_fundo_escuro.png'
import LogoDark from '../../static/images/logo_fundo_claro.png'
import Messages from '../../config/Messages'

/**
 * Os elementos abaixo são obrigatórios
 * os estilos informados são o padrão
 * demais estilos são opcionais
 *
 * @containerBranding
 * coluna da esquerda que contém a
 * logo e um pequeno texto
 *
 * @button
 * botões de submit dos forms
 *
 * @textLink
 * links de resetar senha, criar conta etc
 *
 * @logo
 * logo localizada na coluna da esquerda
 */
export const useSaraivaClasses = makeStyles({
  containerBranding: {
    backgroundImage: `url(${Background})`
  },
  button: {},
  textLink: {},
  textInputBox: {},
  logo: {
    height: '64px',
    marginBottom: '24px'
  },
  mobileLogo: {
    height: '32px'
  }
})

export const saraivaAssets = {
  logo: Logo,
  logoDark: LogoDark,
  logoLabel: 'Saraiva Educação Logo',
  background: Background,
  subtitle: Messages.initialMessage
}
