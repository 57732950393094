function RequestHeaderBuilder() {
  const DEFAULT_REQUEST_HEADER = {
    'Access-Control-Request-Headers': '*',
    'Content-Type': 'application/json'
  }
  function decorate(customRequestHeader = {}) {
    return { ...DEFAULT_REQUEST_HEADER, ...customRequestHeader }
  }
  return {
    buildHeader(overideToken) {
      const sessionToken = localStorage.globalToken
      let applicationToken
      if (overideToken) {
        applicationToken = overideToken
      } else {
        applicationToken = process.env.REACT_APP_APPLICATION_TOKEN
      }
      return decorate({
        'application-token': applicationToken,
        'session-token': sessionToken
      })
    }
  }
}

function RequestBuilder() {
  const { REACT_APP_FUSION_ORIGIN } = process.env
  function normalizePath(path) {
    if (path.startsWith('/')) {
      return path.slice(1)
    }
    return path
  }
  function buildUrl(path) {
    return `${REACT_APP_FUSION_ORIGIN}/${normalizePath(path)}`
  }
  function buildBody(payload) {
    if (!payload) {
      return {}
    }
    return {
      body: JSON.stringify(payload)
    }
  }
  function build(path, method, payload, headers) {
    let options = {
      method,
      headers,
      mode: 'cors'
    }
    if (method !== 'GET') {
      options = { ...options, ...buildBody(payload) }
    }
    return fetch(buildUrl(path), options)
  }
  return {
    post(path, payload, headers) {
      return build(path, 'POST', payload, headers)
    },

    delete(path, payload, headers) {
      return build(path, 'DELETE', payload, headers)
    }
  }
}
export default { ...RequestHeaderBuilder(), ...RequestBuilder() }
