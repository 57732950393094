import { makeStyles } from '@material-ui/core'

import Background from '../../static/images/background_lfg_modern.png'
import Logo from '../../static/images/logo_fundo_escuro_lfg_modern.png'
import LogoDark from '../../static/images/logo_fundo_claro_lfg_modern.png'

/**
 * Os elementos abaixo são obrigatórios
 * os estilos informados são o padrão
 * demais estilos são opcionais
 *
 * @containerBranding
 * coluna da esquerda que contém a
 * logo e um pequeno texto
 *
 * @button
 * botões de submit dos forms
 *
 * @textLink
 * links de resetar senha, criar conta etc
 *
 * @logo
 * logo localizada na coluna da esquerda
 */
export const useLFGModernClasses = makeStyles({
  containerBranding: {
    backgroundImage: `url(${Background})`
  },
  button: {
    backgroundColor: '#5ECFB5',
    color: '#000082',

    '&:hover': {
      backgroundColor: '#1F8879'
    }
  },
  textLink: {
    color: '#4040A1'
  },
  textInputBox: {
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: '#4040A1'
    }
  },
  logo: {
    height: '90px'
  },
  mobileLogo: {
    height: '32px'
  }
})

export const LFGModernAssets = {
  logo: Logo,
  logoDark: LogoDark,
  logoLabel: 'LFG Modern Logo',
  background: Background,
  subtitle: 'Foco na sua conquista!'
}
