import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Link, Typography } from '@material-ui/core'
import imageError from '../static/images/image_error.png'
import Links from '../config/Links'
import Messages from '../config/Messages'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    alignContent: 'center',
    backgroundColor: '#f5f5f5',
    height: '100vh',
    paddingLeft: '40px',
    paddingRight: '40px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '128px',
      paddingRight: '128px'
    }
  },
  textContainer: {
    paddingTop: '64px',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '0px'
    }
  },
  title: {
    fontSize: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '34px'
    }
  },
  subtitle: {
    fontSize: '24px',
    marginTop: '8px',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      marginTop: '24px'
    }
  },
  imageContainer: {
    marginTop: '48px',
    [theme.breakpoints.up('md')]: {
      marginTop: '128px'
    }
  },
  image: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '35vh',
      width: 'auto'
    }
  }
}))

export default function Error() {
  const classes = useStyles()

  return (
    <Link href={Links.login} color="inherit" underline="none">
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} lg={6} className={classes.textContainer}>
          <Typography className={classes.title} variant="h4">
            {Messages.erro404}
          </Typography>
          <Typography className={classes.subtitle} variant="h3">
            {Messages.notFoundMessage}
          </Typography>
        </Grid>
        <Grid dir="rtl" item xs={12} lg={6} className={classes.imageContainer}>
          <img
            alt="Duas pessoas sentadas em um banco"
            src={imageError}
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Link>
  )
}
