import httpClient from './http_request'

const Accounts = {
  async login(payload) {
    let headers = {}
    headers = httpClient.buildHeader()
    const path = 'accounts/api/v1/sessions'
    const response = await httpClient.post(path, payload, headers)
    return response
  },
  async createAccount(payload) {
    let headers = {}
    headers = httpClient.buildHeader()
    const path = 'accounts/api/v1/users'
    const response = await httpClient.post(path, payload, headers)
    return response
  },
  async createAffiliateAccount(payload, originUrl) {
    let headers = {}
    headers = httpClient.buildHeader()
    const path = `accounts/api/v1/create_seller?lfg_url=${originUrl}`
    const response = await httpClient.post(path, payload, headers)

    return response
  },
  async sendEmail(payload) {
    let headers = {}
    const currentTheme = localStorage.getItem('theme')
    if (currentTheme === 'lfgModern') {
      headers = httpClient.buildHeader(
        process.env.REACT_APP_APPLICATION_TOKEN_LFG
      )
    } else {
      headers = httpClient.buildHeader()
    }
    const path = 'accounts/api/v1/password'
    const response = await httpClient.post(path, payload, headers)

    return response
  },
  async updatePassword(payload) {
    let headers = {}
    headers = httpClient.buildHeader()
    const path = 'accounts/api/v1/update'
    const response = await httpClient.post(path, payload, headers)

    return response
  },
  async refreshSession(payload) {
    let headers = {}
    headers = httpClient.buildHeader(headers)
    const path = 'accounts/api/v1/refresh_sessions'
    const response = await httpClient.post(path, payload, headers)
    return response
  },
  async logout(payload) {
    let headers = {}
    headers = httpClient.buildHeader(headers)
    const path = `accounts/api/v1/sessions/${payload.global_token}`
    const response = await httpClient.delete(path, payload, headers)
    return response
  },
  async dummyAction() {
    // ação para forçar um redeploy
  }
}

export default Accounts
