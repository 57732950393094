import React, { useEffect } from 'react'

import Accounts from '../services/accounts'
import Links from '../config/Links'

import { saveGlobalToken, redirectToApplication } from '../utils'

function AutoLogin() {
  const getUrlParams = () => {
    const params = new URL(document.location).searchParams
    const data = {
      previousUrl: params.get('previousUrl'),
      external_application_token: params.get('applicationToken')
    }

    localStorage.setItem('previousUrl', data.previousUrl)
    localStorage.setItem(
      'externalApplicationToken',
      data.external_application_token
    )
    return data
  }

  const storeThemeName = () => {
    const params = new URL(document.location).searchParams
    localStorage.setItem('theme', params.get('theme'))
  }

  const getGlobalToken = () => localStorage.getItem('globalToken')

  const login = async () => {
    const urlParams = getUrlParams()
    const globalToken = getGlobalToken()

    if (globalToken === null) {
      window.location.assign(Links.login)
      return
    }

    const response = await Accounts.refreshSession({
      ...urlParams,
      global_token: globalToken
    })

    if (response.status === 200) {
      const data = await response.json()
      const { tokens } = data
      saveGlobalToken(tokens.global_token)
      redirectToApplication(tokens.local_token, tokens.previous_url)
    } else {
      window.location.assign(Links.login)
    }
  }

  useEffect(() => {
    storeThemeName()
    login()
  })

  return <></>
}

export default AutoLogin
