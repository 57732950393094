module.exports = {
  initialMessage:
    'O conhecimento abre caminho para a descoberta de novas possibilidades',

  requiredField: 'Opa, precisamos desta informação',
  invalidEmail: ' não é um email válido',
  invalidCpf: ' não é um CPF válido',
  cpfTooLong: 'este CPF é inválido, ele deve conter até 11 números',
  equalPassword: 'As senhas precisam ser iguais',
  passwordCharacters:
    'No mínimo 8 caracteres contendo minúscula, maiúscula, número e símbolo',

  accountCreated: 'Parabéns, sua conta foi criada com sucesso',
  incorrectData:
    'Ocorreu um erro. Verifique seus dados de acesso e tente novamente',
  emailExists: 'O email inserido já foi cadastrado anteriormente',
  systemUnavailable:
    'Infelizmente nossos servidores estão indisponíveis no momento. Tente mais tarde',
  subtitlePasswordRecovery:
    'Insira seu e-mail associado à esta conta para receber instruções de redefinição de senha',
  emailSent:
    'O e-mail para redefinir sua senha foi enviado com sucesso! Verifique sua Caixa de Spam caso não o encontre, ou repita o processo',
  passwordReset: 'Sua senha foi redefinida com sucesso',
  erro404: 'Erro 404 - Não encontrado',
  notFoundMessage: 'Infelizmente não localizamos esta página ou documento',
  expiredToken: 'O link para redefinição de senha expirou',
  numbersOnly: 'Campo inválido, use apenas números'
}
