/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext, useEffect } from 'react'
import BrandAssetsAndClasses, { isBrandNameValid } from './brands'

const brandsContext = createContext()

const BrandsProvider = ({ children }) => {
  const [brand, setBrand] = useState(BrandAssetsAndClasses.noBranding)
  const [themeName] = useState(() => {
    return localStorage.getItem('theme')
  })

  const getBrandByName = () => {
    if (!themeName || !isBrandNameValid(themeName)) {
      setBrand(BrandAssetsAndClasses.saraiva)
    } else {
      setBrand(BrandAssetsAndClasses[themeName])
    }
  }

  useEffect(() => {
    getBrandByName()
  })

  return (
    <brandsContext.Provider value={{ brand }}>
      {children}
    </brandsContext.Provider>
  )
}

const useBrands = () => {
  const context = useContext(brandsContext)

  if (!context) {
    throw new Error(
      'O react.context de marcas não foi encontrado. Use o BrandsProvider por volta de todos os componentes que utilizam o useBrands.'
    )
  }

  return context
}

export { useBrands, BrandsProvider }
