import { makeStyles } from '@material-ui/core'
import LogoPlaceholder from '../../static/images/logo_placeholder.png'

/**
 * Os elementos abaixo são obrigatórios
 * os estilos informados são o padrão
 * demais estilos são opcionais
 *
 * @containerBranding
 * coluna da esquerda que contém a
 * logo e um pequeno texto
 *
 * @button
 * botões de submit dos forms
 *
 * @textLink
 * links de resetar senha, criar conta etc
 *
 * @logo
 * logo localizada na coluna da esquerda
 */
export const useNoBrandingClasses = makeStyles({
  containerBranding: {},
  button: {},
  textLink: {},
  textInputBox: {},
  logo: {},
  mobileLogo: {}
})

export const noBrandingAssets = {
  logo: LogoPlaceholder,
  logoDark: LogoPlaceholder,
  logoLabel: '',
  background: '',
  subtitle: ''
}
