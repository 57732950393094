import React, { useState, useEffect } from 'react'
import { Button, Snackbar, TextInput } from '@equipe-ninja/saraiva-ui'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { useBrands } from '../themes/hook'
import Accounts from '../services/accounts'
import Links from '../config/Links'
import Messages from '../config/Messages'
import { isEmail, isEmpty } from './Validators'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    marginTop: '48px',
    marginBottom: '64px',
    marginLeft: '24px',
    marginRight: '24px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
      marginLeft: '15%',
      marginRight: '15%',
      marginBottom: '0px',
      alignSelf: 'center'
    }
  },
  subtitle: {
    lineHeight: 1.5,
    marginTop: '8px',
    fontSize: '16px'
  },
  backButton: {
    marginTop: '16px',
    marginRight: '0px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '0px',
      marginRight: '12px'
    }
  }
}))

export default function PasswordRecovery() {
  const classes = useStyles()
  const { brand } = useBrands()
  const brandClasses = brand.classes()
  const [email, setEmail] = useState('')
  const [verifyEmail, setVerifyEmail] = useState({
    email: { error: false, message: '' }
  })
  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false
  })
  const [disabledButton, setDisabledButton] = useState(true)
  const history = useHistory()

  function inputError() {
    if (verifyEmail.email.error || email.length === 0) setDisabledButton(true)
    else setDisabledButton(false)
  }

  useEffect(() => {
    inputError()
  })

  async function sendEmail() {
    const data = {
      email,
      integration_type: 'email_password'
    }
    try {
      const response = await Accounts.sendEmail(data)
      if (response.status === 204) {
        setSnackbar({
          children: Messages.emailSent,
          severity: 'success',
          show: true
        })
      }
      if (response.status === 401 || response.status === 404) {
        setSnackbar({
          children: Messages.incorrectData,
          severity: 'error',
          show: true
        })
      } else if (response.status === 503) {
        setSnackbar({
          children: Messages.systemUnavailable,
          severity: 'error',
          show: true
        })
      } else if (
        (response.status !== 204 && response.status === 401) ||
        (response.status === 404 && response.status !== 503)
      ) {
        setSnackbar({
          children: Messages.systemUnavailable,
          severity: 'error',
          show: true
        })
      }
    } catch (err) {
      setSnackbar({
        children: Messages.systemUnavailable,
        severity: 'error',
        show: true
      })
    }
  }

  function handleEmailChange(event) {
    setEmail(event.target.value)

    if (isEmpty(event.target.value)) {
      setVerifyEmail({
        email: { error: true, message: Messages.requiredField }
      })
    } else if (!isEmail(event.target.value)) {
      setVerifyEmail({
        email: {
          error: true,
          message: `${event.target.value} ${Messages.invalidEmail}`
        }
      })
    } else {
      setVerifyEmail({ email: { error: false, message: '' } })
    }
  }

  const handleClose = () => {
    setSnackbar({ children: '', severity: 'info', show: false })
  }

  return (
    <>
      <Snackbar
        // eslint-disable-next-line
        children={snackbar.children}
        severity={snackbar.severity}
        show={snackbar.show}
        handleClose={handleClose}
      />

      <form className={classes.mainContainer}>
        <Box display={{ xs: 'block', md: 'none' }} mb={8}>
          <a href={Links.login}>
            <img
              src={brand.assets.logoDark}
              alt={brand.assets.logoLabel}
              className={brandClasses.mobileLogo}
            />
          </a>
        </Box>
        <Box mb={1}>
          <Typography variant="h4">Esqueci minha senha</Typography>
        </Box>
        <Typography className={classes.subtitle}>
          {Messages.subtitlePasswordRecovery}
        </Typography>
        <Box mt={6} className={brandClasses.textInputBox}>
          <TextInput
            onChange={handleEmailChange}
            helperText={verifyEmail.email.message}
            error={verifyEmail.email.error}
            fullWidth
            id="email"
            type="email"
            label="E-mail"
            value={email}
            color="primary"
          />
        </Box>
        <Box mt={3} dir="rtl">
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Button
                disabled={disabledButton}
                color="primary"
                fullWidth
                variant="contained"
                size="small"
                onClick={() => sendEmail()}
                className={brandClasses.button}
              >
                Enviar Instruções
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} className={classes.backButton}>
              <Button
                color="primary"
                fullWidth
                size="small"
                onClick={() => history.push(Links.login)}
                className={brandClasses.textLink}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}
