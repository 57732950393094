module.exports = {
  login: '/login',
  logout: '/logout',
  create_account: '/criar-conta',
  create_account_with_cpf: '/criar-conta-com-cpf',
  create_affiliate_account: '/criar-conta-afiliado',
  password_recovery: '/esqueci-minha-senha',
  reset_password: '/redefinir-senha/:token',
  refresh_session: '/auto-login',
  unauthorized: '/nao-autorizado'
}
