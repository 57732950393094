/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Link, Typography } from '@material-ui/core'
import {
  Button,
  PasswordInput,
  Snackbar,
  TextInput
} from '@equipe-ninja/saraiva-ui'

import Accounts from '../services/accounts'
import Links from '../config/Links'
import Messages from '../config/Messages'
import { useBrands } from '../themes/hook'
import {
  isEmail,
  isEqual,
  isStrength,
  isEmpty,
  isAValidCpf,
  isOnlyNumbers
} from './Validators'
import { saveGlobalToken, redirectToApplication } from '../utils'
import { appTokenBasedOnOrigin } from '../domain/lfg/app_token'
import { affiliateOriginUrl as generateAffiliateOriginUrl } from '../domain/affiliate/affiliate-origin-url'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    marginTop: '48px',
    marginBottom: '64px',
    marginLeft: '24px',
    marginRight: '24px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
      marginLeft: '15%',
      marginRight: '15%',
      marginBottom: '0px',
      alignSelf: 'center'
    }
  }
}))

const CreateAccountWithCpf = ({ isCreatingAffiliateAccount = false }) => {
  const classes = useStyles()
  const { brand } = useBrands()
  const brandClasses = brand.classes()
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verifyName, setVerifyName] = useState({
    name: { error: false, message: '' }
  })
  const [verifyLastName, setVerifyLastName] = useState({
    lastName: { error: false, message: '' }
  })
  const [verifyCpf, setVerifyCpf] = useState({
    cpf: { error: false, message: '' }
  })
  const [verifyEmail, setVerifyEmail] = useState({
    email: { error: false, message: '' }
  })
  const [verifyPassword, setVerifyPassword] = useState({
    password: { error: false, message: Messages.passwordCharacters }
  })
  const [verifyConfirmPassword, setVerifyConfirmPassword] = useState({
    confirmPassword: { error: false, message: '' }
  })
  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false
  })
  const [disabledButton, setDisabledButton] = useState(true)
  const externalApplicationToken = localStorage.getItem(
    'externalApplicationToken'
  )
  const history = useHistory()

  function inputError() {
    if (
      verifyName.name.error ||
      verifyLastName.lastName.error ||
      verifyCpf.cpf.error ||
      verifyEmail.email.error ||
      verifyPassword.password.error ||
      verifyConfirmPassword.confirmPassword.error ||
      name.length === 0 ||
      lastName.length === 0 ||
      cpf.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    )
      setDisabledButton(true)
    else setDisabledButton(false)
  }

  useEffect(() => {
    inputError()
  })

  const affiliateCreationRedirectUrl = (affiliateOriginUrl) => {
    return `${affiliateOriginUrl}/minha-conta/minhas-vendas`
  }

  async function createAccount() {
    const serviceAction = isCreatingAffiliateAccount
      ? Accounts.createAffiliateAccount
      : Accounts.createAccount

    const affiliateOriginUrl = generateAffiliateOriginUrl(
      window.location.origin
    )
    const appTokenFallback = appTokenBasedOnOrigin(window.location.origin)

    const data = {
      first_name: name,
      last_name: lastName,
      id_number: cpf,
      email,
      confirm_email: email,
      password,
      password_confirmation: confirmPassword,
      is_seller: isCreatingAffiliateAccount
    }
    try {
      const response = await serviceAction(data, affiliateOriginUrl)
      if (response.status === 201) {
        setSnackbar({
          children: Messages.accountCreated,
          severity: 'success',
          show: true
        })

        const loginData = {
          email,
          password,
          integration_type: 'email_password',
          external_application_token:
            externalApplicationToken || appTokenFallback
        }

        const loginResponse = await Accounts.login(loginData)
        const responseData = await loginResponse.json()

        if (loginResponse.status === 201) {
          const {
            tokens: { local_token: localToken, global_token: globalToken }
          } = responseData

          saveGlobalToken(globalToken)

          window.setTimeout(() => {
            redirectToApplication(
              localToken,
              affiliateCreationRedirectUrl(affiliateOriginUrl)
            )
          }, 1000)
        } else if (
          loginResponse.status === 401 &&
          responseData.error === 'user do not have access to application'
        ) {
          history.push(Links.unauthorized)
        } else {
          setSnackbar({
            children: Messages.systemUnavailable,
            severity: 'error',
            show: true
          })
        }
      } else if (response.status === 422) {
        setSnackbar({
          children: Messages.emailExists,
          severity: 'error',
          show: true
        })
      } else {
        setSnackbar({
          children: Messages.systemUnavailable,
          severity: 'error',
          show: true
        })
      }
    } catch (err) {
      setSnackbar({
        children: Messages.systemUnavailable,
        severity: 'error',
        show: true
      })
    }
  }

  function handleNameChange(event) {
    setName(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyName({
        name: { error: true, message: Messages.requiredField }
      })
    } else {
      setVerifyName({ name: { error: false, message: '' } })
    }
  }

  function handleLastNameChange(event) {
    setLastName(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyLastName({
        lastName: { error: true, message: Messages.requiredField }
      })
    } else {
      setVerifyLastName({ lastName: { error: false, message: '' } })
    }
  }

  function handleCpfChange(event) {
    setCpf(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyCpf({
        cpf: { error: true, message: Messages.requiredField }
      })
    } else if (event.target.value.length > 11) {
      setVerifyCpf({
        cpf: {
          error: true,
          message: Messages.cpfTooLong
        }
      })
    } else if (!isOnlyNumbers(event.target.value)) {
      setVerifyCpf({
        cpf: {
          error: true,
          message: Messages.numbersOnly
        }
      })
    } else if (!isAValidCpf(event.target.value)) {
      setVerifyCpf({
        cpf: {
          error: true,
          message: `${event.target.value}${Messages.invalidCpf}`
        }
      })
    } else {
      setVerifyCpf({ cpf: { error: false, message: '' } })
    }
  }

  function handleEmailChange(event) {
    setEmail(event.target.value)

    if (isEmpty(event.target.value)) {
      setVerifyEmail({
        email: { error: true, message: Messages.requiredField }
      })
    } else if (!isEmail(event.target.value)) {
      setVerifyEmail({
        email: {
          error: true,
          message: `${event.target.value}${Messages.invalidEmail}`
        }
      })
    } else {
      setVerifyEmail({ email: { error: false, message: '' } })
    }
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyPassword({
        password: {
          error: true,
          message: Messages.requiredField
        }
      })
    } else if (!isStrength(event.target.value)) {
      setVerifyPassword({
        password: { error: true, message: Messages.passwordCharacters }
      })
    } else {
      setVerifyPassword({ password: { error: false, message: '' } })
    }
  }

  function handleConfirmPasswordChange(event) {
    setConfirmPassword(event.target.value)
    if (isEmpty(event.target.value)) {
      setVerifyConfirmPassword({
        confirmPassword: {
          error: true,
          message: Messages.requiredField
        }
      })
    } else if (!isEqual(password, event.target.value)) {
      setVerifyConfirmPassword({
        confirmPassword: {
          error: true,
          message: Messages.equalPassword
        }
      })
    } else {
      setVerifyConfirmPassword({
        confirmPassword: { error: false, message: '' }
      })
    }
  }

  const handleClose = () => {
    setSnackbar({ children: '', severity: 'info', show: false })
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (brand?.name === 'lfgModern') {
      window.setGtag()
    }
  }, [brand.name])

  return (
    <>
      <Snackbar
        // eslint-disable-next-line
        children={snackbar.children}
        severity={snackbar.severity}
        show={snackbar.show}
        handleClose={handleClose}
      />

      <form className={classes.mainContainer}>
        <Box display={{ xs: 'block', md: 'none' }} mb={8}>
          <a href={Links.login}>
            <img
              src={brand.assets.logoDark}
              alt={brand.assets.logoLabel}
              className={brandClasses.mobileLogo}
            />
          </a>
        </Box>
        <Box mb={6}>
          <Typography variant="h4">Criar uma conta</Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className={brandClasses.textInputBox}>
            <TextInput
              onChange={handleNameChange}
              helperText={verifyName.name.message}
              error={verifyName.name.error}
              fullWidth
              id="name"
              type="name"
              label="Nome"
              value={name}
              color="primary"
            />
          </Grid>
          <Grid item xs={12} md={6} className={brandClasses.textInputBox}>
            <TextInput
              onChange={handleLastNameChange}
              helperText={verifyLastName.lastName.message}
              error={verifyLastName.lastName.error}
              fullWidth
              id="lastName"
              type="lastName"
              label="Sobrenome"
              value={lastName}
              color="primary"
            />
          </Grid>
        </Grid>

        <Box mt={3} className={brandClasses.textInputBox}>
          <TextInput
            onChange={handleCpfChange}
            helperText={verifyCpf.cpf.message}
            error={verifyCpf.cpf.error}
            fullWidth
            id="cpf"
            type="text"
            label="CPF"
            value={cpf}
            color="primary"
          />
        </Box>

        <Box mt={3} className={brandClasses.textInputBox}>
          <TextInput
            onChange={handleEmailChange}
            helperText={verifyEmail.email.message}
            error={verifyEmail.email.error}
            fullWidth
            id="email"
            type="email"
            label="E-mail"
            value={email}
            color="primary"
          />
        </Box>
        <Box mt={3} className={brandClasses.textInputBox}>
          <PasswordInput
            onChange={handlePasswordChange}
            helperText={verifyPassword.password.message}
            error={verifyPassword.password.error}
            fullWidth
            id="password"
            label="Senha"
            value={password}
            color="primary"
          />
        </Box>
        <Box mt={3} className={brandClasses.textInputBox}>
          <PasswordInput
            onChange={handleConfirmPasswordChange}
            helperText={verifyConfirmPassword.confirmPassword.message}
            error={verifyConfirmPassword.confirmPassword.error}
            fullWidth
            id="confirmPassword"
            label="Confirme sua senha"
            value={confirmPassword}
            color="primary"
          />
        </Box>
        <Box mt={6}>
          <Button
            disabled={disabledButton}
            color="primary"
            fullWidth
            variant="contained"
            size="small"
            onClick={() => createAccount()}
            className={brandClasses.button}
          >
            Criar Conta
          </Button>
        </Box>
        <Box mt={3}>
          <Typography align="center" color="textSecondary">
            Já possui conta?&nbsp;
            <Link href={Links.login} className={brandClasses.textLink}>
              Acessar conta
            </Link>
          </Typography>
        </Box>
      </form>
    </>
  )
}

export default CreateAccountWithCpf
