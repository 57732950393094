export function isEmpty(input) {
  return input.length === 0
}

export function isEmail(input) {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
  return expression.test(String(input).toLowerCase())
}

export function isEqual(input1, input2) {
  return input1 === input2
}

export function isStrength(input) {
  const expression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!%+-/?|_])[0-9a-zA-Z$*&@#!%+-/?|_]{8,}$/
  return expression.test(input)
}

export function isCpf(input) {
  const expression = /^[0-9]{11}/
  return expression.test(input)
}

export function isOnlyNumbers(input) {
  const expression = /^[0-9]*$/
  return expression.test(input)
}

/**
 * checa a composição dos digitos do cpf para validar os digitos verificadores
 * @param {number[]} digits
 */
function verifyCpfAlgorithm(digits) {
  if (digits.length !== 11) return false

  const computingDigitsForFirstDigitVerification = digits.slice(0, 9)
  const computingDigitsForSecondDigitVerification = digits.slice(0, 10)
  const multipliersForFirstDigit = new Array(9).fill(null).map((e, i) => 10 - i)
  const multipliersForSecondDigit = new Array(10)
    .fill(null)
    .map((e, i) => 11 - i)
  const firstVerificationDigit = digits[9]
  const secondVerificationDigit = digits[10]

  const sumOfDigitsMultiplicationFirstDigit = computingDigitsForFirstDigitVerification.reduce(
    (partialSum, digit, index) =>
      partialSum + digit * multipliersForFirstDigit[index],
    0
  )

  let computedVerificationDigit =
    (sumOfDigitsMultiplicationFirstDigit * 10) % 11

  computedVerificationDigit =
    computedVerificationDigit >= 10 ? 0 : computedVerificationDigit

  if (computedVerificationDigit !== firstVerificationDigit) return false

  const sumOfDigitsMultiplicationSecondDigit = computingDigitsForSecondDigitVerification.reduce(
    (partialSum, digit, index) =>
      partialSum + digit * multipliersForSecondDigit[index],
    0
  )

  computedVerificationDigit = (sumOfDigitsMultiplicationSecondDigit * 10) % 11
  computedVerificationDigit =
    computedVerificationDigit >= 10 ? 0 : computedVerificationDigit

  if (computedVerificationDigit !== secondVerificationDigit) return false

  return true
}

/**
 *
 * @param {string} input
 */
export function isAValidCpf(input) {
  const digits = input
    .replace(/\D/g, '')
    .split('')
    .map((v) => Number.parseInt(v, 10))

  if (digits.length !== 11) return false

  return verifyCpfAlgorithm(digits)
}
