function saveGlobalToken(globalToken) {
  localStorage.setItem('globalToken', globalToken)
}

function redirectToApplication(localToken, previous) {
  let previousUrl = localStorage.getItem('previousUrl')

  if (previousUrl === null || previousUrl === undefined) {
    previousUrl = `${previous}?sessionToken=${localToken}`
  } else {
    previousUrl = `${previousUrl}?sessionToken=${localToken}`
  }

  localStorage.removeItem('previousUrl')
  window.location.href = previousUrl
}

export { saveGlobalToken, redirectToApplication }
