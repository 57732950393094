const LFG_APP_TOKEN = {
  PRODUCTION: 'ZpxCyRGHqLJT8Xdwhzs2NdTFKHI3PfKx',
  STAGING: 'sUQVhyFzGC5T1iVk3rrMsR8DKDPJapn4',
  DEVELOP: 'db019cb11c66098998d629533cb6a9c6'
}

export const appTokenBasedOnOrigin = (origin) => {
  if (origin.match(/localhost/)) {
    return LFG_APP_TOKEN.DEVELOP
  }

  if (origin.match(/stg\.sso\.saraiva/)) {
    return LFG_APP_TOKEN.STAGING
  }

  return LFG_APP_TOKEN.PRODUCTION
}
