import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import Links from '../config/Links'
import { useBrands } from '../themes/hook'

const useStyles = makeStyles({
  columnBranding: {
    color: '#ffffff',
    height: '100vh',
    paddingRight: '20%',
    paddingLeft: '20%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  columnForm: {
    minHeight: '100vh',
    backgroundColor: '#f5f5f5'
  }
})
export default function Layout(Form) {
  const classes = useStyles()

  const { brand } = useBrands()

  const brandClasses = brand.classes()

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box
          className={`${classes.columnBranding} ${brandClasses.containerBranding}`}
          display={{ xs: 'none', md: 'flex' }}
        >
          <a href={Links.login}>
            <img
              src={brand.assets.logo}
              alt={brand.assets.logoLabel}
              className={brandClasses.logo}
            />
          </a>
          <Box>
            <Typography variant="h5">{brand.assets.subtitle}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container item xs={12} md={6} className={classes.columnForm}>
        <Form />
      </Grid>
    </Grid>
  )
}
