import React, { useEffect } from 'react'

import Accounts from '../services/accounts'
import Links from '../config/Links'

function Logout() {
  const globalToken = localStorage.getItem('globalToken')

  const logout = async () => {
    const response = await Accounts.logout({
      global_token: globalToken
    })

    if (response.status === 200) {
      const params = new URL(document.location).searchParams

      if (params.get('previousUrl')) {
        window.location.assign(params.get('previousUrl'))
      } else {
        window.location.assign(Links.login)
      }
    } else {
      window.location.href = document.referrer
    }
  }

  useEffect(() => {
    logout()
  })

  return <></>
}

export default Logout
